import React, { useState, useEffect, useRef } from 'react'
import './Chat.css'
import Send from '../components/assets/svgs/Send'
import Message from '../components/Message'
import { useNavigate } from 'react-router-dom'

function Chat() {
  const [messages, setMessages] = useState([
    {
      id: 1,
      content: 'Hi! I am Alice, your sales assistant.',
      role: 'assistant'
    },
    {
      id: 2,
      content: 'Would you like to get to know me? \nAsk me anything!',
      role: 'assistant'
    }
  ])
  const [visibleMessages, setVisibleMessages] = useState([])
  const [input, setInput] = useState('')
  const [assistantIsTyping, setAssistantIsTyping] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    // Show messages one by one with delay
    messages.forEach((message, index) => {
      setTimeout(() => {
        setVisibleMessages(prev => [...prev, message])
      }, index * 1000) // 1000ms delay between each message
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const button = document.querySelector('.learn-more-button')
      button.classList.add('shake')
      
      // Remove the class after animation completes
      setTimeout(() => {
        button.classList.remove('shake')
      }, 500)
    }, 10000) // Run every 10 seconds

    return () => clearInterval(interval)
  }, [])

  const handleInputOptionClick = (content, id) => {
    if (assistantIsTyping) return
    setVisibleMessages(prev => [...prev, { id, content, role: 'user' }])
    setAssistantIsTyping(true)

    createAssistantResponse(id)
  }

  const handleSend = () => {
    if (assistantIsTyping) return
    setVisibleMessages(prev => [...prev, { id: prev.length + 1, content: input, role: 'user' }])
    setInput('')
    setAssistantIsTyping(true)
  }

  const createAssistantResponse = (id) => {
    const responses = {
      1: [
        'Hello! I am Alice, your sales assistant.',
        'Hi there! Alice here, ready to help with your sales needs.',
        "Greetings! I'm Alice, your AI sales assistant.",
      ],
      2: [
        "I am a virtual assistant created by the company Alice AI.",
        "I'm an AI assistant, brought to you by Alice AI.",
        "Alice AI developed me to be your virtual sales assistant.",
      ],
      3: [
        'I can help you with your sales process. I can answer questions about the product, help you with your sales administration, and more.',
        'My expertise includes product information, CRM administration, and helping you close deals effectively.',
        'From prospect finding to sales administration, I\'m here to support your entire sales journey.',
      ],
      4: [
        "My purpose is to help you with your sales process. I can answer questions about the product, help you with your sales administration, and more.",
        "I'm designed to streamline your sales process and help you achieve better results.",
        "I'm here to be your sales companion, helping you every step of the way.",
      ],
      5: [
        'Leave your email address here and we will get back to you for scheduling a demo.',
      ],
    }

    const randomIndex = Math.floor(Math.random() * responses[id].length)
    const response = responses[id][randomIndex]

    setTimeout(() => {
      setVisibleMessages(prev => [...prev, { id, content: response, role: 'assistant' }])
      setAssistantIsTyping(false)
    }, 1000)
  }

  return (
    <div className='chat-container'>
      <h1>alice</h1>
      <button className='learn-more-button' onClick={() => navigate('/meet-alice')}>Learn more</button>
      <div className='chat-body'>
        <div className='chat-messages'>
          {visibleMessages.map((message, index) => (
            <Message 
              key={message.id}
              message={message} 
              isAssistant={message.role === 'assistant'} 
              isContinuation={index > 0 && visibleMessages[index - 1].role === message.role}
            />
          ))}
          {assistantIsTyping && <div className='typing-indicator'>
            <div className='typing-dot'></div>
            <div className='typing-dot'></div>
            <div className='typing-dot'></div>
          </div>}
        </div>
        <div className='chat-input-container'>
          <div className='chat-input'>
            <input type='text' placeholder='Type a message...' value={input} onChange={(e) => setInput(e.target.value)} />
            <button onClick={handleSend} disabled={assistantIsTyping}><Send /></button>
          </div>
          <div className='chat-input-options'>
            <div className='chat-input-option' onClick={() => handleInputOptionClick('Hi!', 1)} disabled={assistantIsTyping}>Hi!</div>
            <div className='chat-input-option' onClick={() => handleInputOptionClick('Are you real?', 2)} disabled={assistantIsTyping}>Are you real?</div>
            <div className='chat-input-option' onClick={() => handleInputOptionClick('What tasks can you do?', 3)} disabled={assistantIsTyping}>What tasks can you do?</div>
            <div className='chat-input-option' onClick={() => handleInputOptionClick('What is your purpose?', 4)} disabled={assistantIsTyping}>What is your purpose?</div>
            <div className='chat-input-option' onClick={() => handleInputOptionClick('I am interested! How to get started?', 5)} disabled={assistantIsTyping}>I am interested! How to get started?</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat