import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './Home.css'
import { IoIosPlay } from "react-icons/io"
import { IoIosStarOutline } from "react-icons/io"
import { VscMail } from "react-icons/vsc"
import { MdOutlinePrivacyTip } from "react-icons/md"
import { CircularProgress } from '@mui/material';

function Home() {
  const mainImage = require('../components/assets/main_image.png')
  const videoThumbnail = require('../components/assets/video_thumbnail.png')
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/api/book-demo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, phone })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Demo request received:', data)
      setIsSubmitted(true)
      setIsLoading(false)
    })
    .catch(error => {
      console.error('Error submitting demo request:', error)
      setIsLoading(false)
    })
  }

  return (

    <div className='home-container'>

      <div className='main-image-container'>
        <button className='book-demo-button' onClick={() => setShowModal(true)}>Book a Demo</button>

        <h1>alice</h1>
        <div className='video-container' onClick={() => {
          window.open('https://youtu.be/WJSxaKZGs_s', '_blank')
        }}>
          <IoIosPlay className='play-icon' />
          <img src={videoThumbnail} alt='Alice AI' />
        </div>
        <img src={mainImage} alt='Alice AI' />
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Book a Demo</h2>
            {!isSubmitted ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="modal-buttons">
                  <button type="button" onClick={() => setShowModal(false)}>Cancel</button>
                  <button type="submit" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                  </button>
                </div>
              </form>
            ) : (
              <div className="submission-success">
                <p>We will be in contact shortly!</p>
                <div className="modal-buttons">
                  <button type="button" onClick={() => {
                    setShowModal(false);
                    setIsSubmitted(false);
                    setEmail('');
                    setPhone('');
                  }}>OK</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className='home-content-container'>
        <div className='home-content-text'>
          <h1>alice</h1>
          <p>Your AI Sales Assistant</p>
        </div>

        <div className='menu-container'>
          <div className='menu-item'>
            <div className='menu-item-icon'>
              <IoIosStarOutline />
              <IoIosStarOutline />
              <IoIosStarOutline />
            </div>
            <p>Customer Stories</p>
          </div>
          <div className='menu-item' onClick={() => {
            navigate('/contact')
          }}>
            <div className='menu-item-icon'>
              <VscMail />
            </div>
            <p>Contact Us</p>
          </div>
          <div className='menu-item' onClick={() => {
            navigate('/privacy-policy')
          }}>
            <div className='menu-item-icon'>
              <MdOutlinePrivacyTip />
            </div>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home