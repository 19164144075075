import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './Contact.css'

function Contact() {
  const navigate = useNavigate()

  return (
    <div className='contact-container'>
      <button className='back-button' onClick={() => navigate(-1)}>
        ← back
      </button>
      <h1>Contact Us</h1>

      <div className='contact-content'>
        <p>Email: hello@hi-alice.com</p>
        <p>Phone: +32 479 71 86 51</p>
      </div>
    </div>
  )
}

export default Contact