import * as React from "react";

const Send = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Monochrome" viewBox="0 0 24 24">
    <path
      fill="#021e38"
      d="m22.63 2.78-3.76 17.47c-.08.38-.33.7-.68.87s-.75.17-1.11 0l-2.8-1.36s-3.58 2.59-4.31 2.93c-.14.07-.2.06-.3.06a.748.748 0 0 1-.75-.74V16.7c-.01-.11 0-.22.06-.33s.01-.02.02-.03c.03-.05.06-.1.1-.15l9.21-10.08-11.45 9.42a.755.755 0 0 1-.96.1l-3.85-1.74c-.43-.21-.7-.63-.72-1.11-.01-.47.24-.91.66-1.14l18.8-10.23c.44-.24.96-.2 1.37.1.4.3.58.79.48 1.27z"
    ></path>
  </svg>
);

export default Send;