import React, { useState, useEffect, useRef } from 'react'
import './Message.css'

function Message({ message, isAssistant, isContinuation }) {
  const AssistantAvatar = require('./assets/alice.png')
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Trigger animation after component mount
    setTimeout(() => {
      setIsVisible(true)
    }, 100)
  }, [])

  return (
    <div className={`message ${isAssistant ? 'assistant' : 'user'} ${isVisible ? 'visible' : ''}`}>
      <div className='message-avatar  '>
        {isAssistant && !isContinuation && <img src={AssistantAvatar} alt='assistant'  />}
      </div>
      <div className='message-content' style={{ whiteSpace: 'pre-wrap' }}>
        {message.content}
      </div>
    </div>
  )
}

export default Message