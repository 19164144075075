import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  const navigate = useNavigate()

  return (
    <div className='privacy-policy-container'>
      <button className='back-button' onClick={() => navigate(-1)}>
        ← back
      </button>
      
      <h1>Privacy Policy</h1>

      <div className='privacy-policy-content'>
        <p>Last updated: 23rd November 2024</p>

        <h2>1. Introduction</h2>

        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from hi-alice.com (the "Site").</p>

        <h2>2. Personal Information We Collect</h2>

        <p>In Short: We collect personal information that you provide to us.</p>

        <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

        <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
        </ul>

        <p>Sensitive Information. We do not process sensitive information. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy